import React from "react"
import Layout from "../components/layout"

export default function Home() {
  return (
    <Layout>
      <div className="wordMarkContainer">
        <h1 className="icon">R</h1>
        <h2 className="appName">Reprise</h2>
        <p className="tagline">Share your greatest musical moments</p>
        <h3>Coming Soon!</h3>
        <p className="access">For early access, email <a href={'mailto:feedback@repriserapp.com'}>feedback@repriserapp.com</a></p>
      </div>
    </Layout>
  )
}
